import React from 'react'
import { AppBar, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Link from '../link'
import NavLinks from '../navLinks/navLinks'
import { FooterLinks, Homepage } from '../../utils/navLinks'

import Charbz from '../../assets/svg/charbz.svg'
import FullLogo from '../../assets/svg/fullLogo.svg'
import SignUpButton from '../signUpButton/SignUpButton'

const Footer = () => {
    const { t } = useTranslation('footer')

    return (
        <AppBar position="absolute" component="footer" elevation={0}>
            <div className="footerContents">
                <div className="footerTop">
                    <IconButton
                        href={Homepage.path}
                        component={Link}
                        aria-label="home"
                        className="footerLogo"
                        size="large"
                    >
                        <FullLogo />
                    </IconButton>
                    <div className="footerContentsRHS">
                        <div className="footerLinks">
                            <span className="footerTitle">{t('pages')}</span>
                            <NavLinks links={FooterLinks} />
                        </div>
                        <div className="footerForm">
                            <span className="footerTitle">{t('newsletter')}</span>
                            <SignUpButton text="Join our waitlist" arrow />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="footerCredits">
                    <span>{`© ${new Date().getFullYear()} Kourai Inc.`}</span>
                    <span className="dash">&nbsp;-&nbsp;</span>
                    <a
                        href="https://charbz.dev"
                        target="_blank"
                        rel="noreferrer"
                        className="madeBy"
                    >
                        <span>{t('siteBy')}</span>
                        <Charbz className="charbzLogo" />
                    </a>
                </div>
            </div>
        </AppBar>
    )
}

export default Footer
