import React from 'react'
import { Button } from '@mui/material'
import EastIcon from '@mui/icons-material/East'
import Link from '../link'

interface SignUpButtonProps {
    text?: string
    arrow?: boolean
}

const SignUpButton = ({ text = 'Join Waitlist', arrow = false }: SignUpButtonProps) => (
    <Button
        href="/waitlist#signUp"
        component={Link}
        variant="contained"
        disableElevation
        className="signUpButton"
        endIcon={arrow && <EastIcon />}
    >
        {text}
    </Button>
)

export default SignUpButton
