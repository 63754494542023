import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import Link from '../link'
import { NavLinkType } from '../../utils/navLinks'

interface NavLinksProps {
    links: NavLinkType[]
}

const NavLinks = ({ links }: NavLinksProps) => {
    const { t } = useTranslation()

    return (
        <div className="navLinks">
            {links.map(navLink => (
                <Button
                    href={navLink.scrollPath ? navLink.scrollPath : navLink.path}
                    component={Link}
                    key={navLink.id}
                    className="navLink"
                >
                    {t(`${navLink.id}:title`)}
                </Button>
            ))}
        </div>
    )
}

export default NavLinks
