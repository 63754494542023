import React, { useState } from 'react'
import { IconButton, SwipeableDrawer, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import NavLinks from '../navLinks/navLinks'
import { DrawerLinks } from '../../utils/navLinks'
import SignUpButton from '../signUpButton/SignUpButton'

import Menu from '../../assets/svg/navIcons/menu.svg'
import Logo from '../../assets/svg/logo.svg'

const Drawer = () => {
    const [openDrawer, setDrawerOpen] = useState(false)
    // const iOS = typeof window === 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return
        }
        setDrawerOpen(open)
    }

    const mobileNavContents = (
        <div
            role="presentation"
            className="drawerContents"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <div>
                <NavLinks links={DrawerLinks} />
                <SignUpButton />
            </div>

            <Logo className="drawerLogo" />
        </div>
    )

    return (
        <>
            <Tooltip
                title="Menu"
                aria-label="Menu"
                classes={{ popper: 'navPopper', tooltip: 'navTooltip' }}
            >
                <IconButton
                    aria-label="menu"
                    className="navMenuButton"
                    onClick={toggleDrawer(true)}
                    size="large"
                >
                    <Menu />
                </IconButton>
            </Tooltip>
            <SwipeableDrawer
                anchor="right"
                open={openDrawer}
                classes={{ paper: 'drawer' }}
                disableDiscovery
                onOpen={toggleDrawer(true)}
                onClose={toggleDrawer(false)}
                // disableBackdropTransition={!iOS}
            >
                {mobileNavContents}
                <IconButton
                    aria-label="close"
                    className="closeButton"
                    onClick={toggleDrawer(false)}
                    size="large"
                >
                    <CloseIcon fontSize="large" />
                </IconButton>
            </SwipeableDrawer>
        </>
    )
}

export default Drawer
