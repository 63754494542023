/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import useDarkMode from 'use-dark-mode'

import { usePageContext } from './pageContext'
import { Homepage } from '../utils/navLinks'
import useSiteMetadata from '../hooks/useSiteMetadata'

interface SeoProps {
    title: string
    description?: string
    image?: string
    alreadyTranslated?: boolean
    meta?: any[]
}

const SEO = ({
    description = '',
    meta = [],
    title,
    image = '',
    alreadyTranslated = false,
}: SeoProps) => {
    const {
        title: defaultTitle,
        description: defaultDescription,
        image: defaultImage,
        url,
    } = useSiteMetadata()

    const { lang, originalPath } = usePageContext()
    const { t } = useTranslation()

    const isHomepage = originalPath === Homepage.path
    const translatedString = (string: string) =>
        alreadyTranslated ? string : t(string, { lng: lang })

    const seo = {
        title: isHomepage ? defaultTitle : `${translatedString(title)} | ${defaultTitle}`,
        description: description ? translatedString(description) : defaultDescription,
        image: `${url}${image || defaultImage}`,
    }

    const darkMode = useDarkMode(false)
    const themeColor = darkMode.value ? '#002D3D' : '#107BA6'

    return (
        <Helmet
            htmlAttributes={{ lang: lang || 'en' }}
            title={title}
            titleTemplate={seo.title}
            meta={[
                {
                    name: `description`,
                    content: seo.description,
                },
                {
                    name: `image`,
                    content: seo.image,
                },
                {
                    property: `og:title`,
                    content: seo.title,
                },
                {
                    property: `og:description`,
                    content: seo.description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:url`,
                    content: url,
                },
                {
                    property: `og:image`,
                    content: seo.image,
                },
                {
                    property: `og:locale`,
                    content: lang,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                // {
                //     name: `twitter:creator`,
                //     content: site.siteMetadata.author,
                // },
                {
                    name: `twitter:title`,
                    content: seo.title,
                },
                {
                    name: `twitter:description`,
                    content: seo.description,
                },
                {
                    name: `twitter:url`,
                    content: url,
                },
                {
                    property: `twitter:image`,
                    content: seo.image,
                },
                // Safari Top bar color
                {
                    name: `theme-color`,
                    content: themeColor,
                },
            ].concat(meta)}
        />
    )
}

export default SEO
