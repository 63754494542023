import React from 'react'
import { AppBar, Toolbar, IconButton } from '@mui/material'

import Drawer from '../drawer/drawer'
import Link from '../link'
import ThemeToggle from '../themeToggle/themeToggle'
import NavLinks from '../navLinks/navLinks'
import { HeaderLinks, Homepage } from '../../utils/navLinks'
import SignUpButton from '../signUpButton/SignUpButton'

import FullLogo from '../../assets/svg/fullLogo.svg'

const Header = () => (
    <AppBar position="fixed" elevation={0}>
        <Toolbar component="nav" disableGutters>
            <div className="navLHS">
                <IconButton
                    href={Homepage.path}
                    component={Link}
                    aria-label="home"
                    className="navLogo"
                    size="large"
                >
                    <FullLogo />
                </IconButton>
            </div>
            <NavLinks links={HeaderLinks} />
            <div className="navRHS">
                <ThemeToggle />
                <SignUpButton />
                <Drawer />
            </div>
        </Toolbar>
    </AppBar>
)

export default Header
