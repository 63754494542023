import React, { forwardRef, ReactNode } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { useTranslation } from 'react-i18next'
import currentLanguages from '../i18n/config/currentLanguages'

interface LinkProps {
    href?: string
    children: ReactNode
    className?: string
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(({ href = '', ...rest }, ref: any) => {
    const { i18n } = useTranslation()
    const currentLanguage = currentLanguages.find(language => i18n.language === language.shorthand)

    return <GatsbyLink {...rest} to={`${currentLanguage?.path}${href}`} ref={ref} />
})

export default Link
