export interface NavLinkType {
    id: string
    path: string
    scrollPath?: string
}

export const Homepage = {
    id: 'homepage',
    path: '/',
    scrollPath: '/',
}

export const Problem = {
    id: 'problem',
    path: '/problem/',
    scrollPath: '/#problem',
}

export const System = {
    id: 'system',
    path: '/system/',
    scrollPath: '/#system',
}

export const About = {
    id: 'about',
    path: '/about/',
    scrollPath: '/#about',
}

export const FAQ = {
    id: 'faq',
    path: '/faq/',
    scrollPath: '/#faq',
}

export const Contact = {
    id: 'contact',
    path: '/contact/',
    scrollPath: '/#contact',
}

export const PreOrder = {
    id: 'preorder',
    path: '/pre-order/',
}

export const Terms = {
    id: 'terms',
    path: '/termsandprivacy/',
}

export const DrawerLinks: NavLinkType[] = [Homepage, Problem, System, About, FAQ, Contact]
export const HeaderLinks: NavLinkType[] = [Problem, System, About, FAQ, Contact]
export const FooterLinks: NavLinkType[] = [Homepage, Problem, System, About, FAQ, Contact, Terms]
